import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'

export const Enroll = async () => {
    let data
    try {
        data = await Api.get('/api/auth/2fa/enroll')
    } catch (error) {
        eventBus.$emit('showSnackBar', error, 'error')
    }
    return data.data
}

export const codeValidate = async (payload) => {
    let data
    try {
        data = await Api.post('/api/auth/2fa/validate', { one_time_password: payload })
    } catch (error) {
        eventBus.$emit('showSnackBar', error, 'error')
    }
    return data.data
}

export const EnableTwoFa = async (payload) => {
    let data
    try {
        data = await Api.post('/api/auth/2fa/enable', { one_time_password: payload })
    } catch (error) {
        eventBus.$emit('showSnackBar', error, 'error')
    }
    return data.data
}

export const DisableTwoFa = async () => {
    try {
        await Api.get('/api/auth/2fa/disable')
    } catch (error) {
        eventBus.$emit('showSnackBar', error, 'error')
    }
}
